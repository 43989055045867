import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {TenantService} from "./tenant.service";
import {TokenStore} from "../modules/auth/models/TokenStore";
import { DateTime } from "luxon";
import {SubscriptionStatus} from "../models/SubscriptionStatus";
import {CurrentUser} from "../models/CurrentUser";
import {OnboardingFlags} from "../models/OnboardingFlags";

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  currentUser$: Observable<CurrentUser | any |  null>;
  isLoading$: Observable<boolean>;
  currentPaymentStatus$: Observable<SubscriptionStatus | null>;
  currentUserSubject: BehaviorSubject<CurrentUser | any | null> =  new BehaviorSubject<CurrentUser | any | null>(null);
  currentPaymentStatusSubject: BehaviorSubject<SubscriptionStatus | null> = new BehaviorSubject<any>(undefined);
  currentToken$: Observable<TokenStore | null>;
  currentTokenSubject: BehaviorSubject<TokenStore | null> = new BehaviorSubject<TokenStore | null>(null);
  currentPackage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentFeatures: BehaviorSubject<any> = new BehaviorSubject<any>({});
  onboardingFlags: BehaviorSubject<OnboardingFlags | null> = new BehaviorSubject<OnboardingFlags | null>(null);
  currentUserType: BehaviorSubject<string> =  new BehaviorSubject<string>('');
  isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  authenticatedOptions: any;
  tokenSubscription: Subscription;

  constructor(
    private http: HttpClient,
    public cookieService: CookieService,
    public tenant: TenantService,
    private router: Router
  ) {
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentToken$ = this.currentTokenSubject.asObservable();
    this.currentPaymentStatus$ = this.currentPaymentStatusSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();

    this.tokenSubscription = this.currentToken$.subscribe((data: TokenStore | null) => {
      if(data){
        this.authenticatedOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + data.access_token
          }),
        };
      }
    });

  }

  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

  get currentTokenValue(): any {
    return this.currentTokenSubject.value;
  }

  set currentTokenValue(user: any) {
    this.currentTokenSubject.next(user);
  }

  // public methods
  login(loginData: any): Promise<any> {

    loginData.client_id = 2;
    loginData.grant_type = 'password';
    loginData.scope = '';

    this.isLoadingSubject.next(true);
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/oauth/token', loginData, this.tenant.getUnAuthenticatedHeader())
        .subscribe(
          (res) => {
            this.isLoadingSubject.next(false);
            resolve(res);
          },
          err => {
            this.isLoadingSubject.next(false);
            reject(err);
          },
        );
    });

  }

  requestNewPassword(data: any, type: 'core' | 'influencer' | 'client' = 'core'){

    data.type = type;

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/reset/request', data, this.tenant.getUnAuthenticatedHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  checkResetToken(data: any){

      return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/reset/code', data, this.tenant.getUnAuthenticatedHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  changePasswordReset(data: any){

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/reset/reset', data, this.tenant.getUnAuthenticatedHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  renewToken(data: TokenStore): Promise<any> {

    const TokenRequestData = {
      grant_type: 'refresh_token',
      refresh_token : data.refresh_token,
      client_id: 2,
      scope: ''
    }

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/oauth/token', TokenRequestData, this.tenant.getUnAuthenticatedHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  logout() {
    this.destroySession();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  destroySession() {
    this.cookieService.delete('qrpatd');
    this.cookieService.delete('qrpay_tenant');
    this.cookieService.delete('qrpay_domain');
    this.tenant.currentTenantSubject.next(null);
    this.tenant.currentDomainSubject.next(null);
    this.currentUserSubject.next(null);
    this.currentTokenSubject.next(null);
    this.currentPaymentStatusSubject.next(null);

  }

  ngOnDestroy() {
  }

  public saveTokenToStorage(data: TokenStore): TokenStore{
    data.expires_at = DateTime.now().plus({seconds: (data.expires_in - 100)}).toJSDate();
    this.cookieService.set('qrpatd', JSON.stringify(data), {path: '/', sameSite: 'Lax'});
    return data;
  }

  public retrieveTokenFromStorage(): TokenStore | null{
    if(this.cookieService.check('qrpatd')){
      return JSON.parse(this.cookieService.get('qrpatd'))
    }else{
      return null;
    }
  }

  containsCustomDomain(data: string){

    return data.indexOf('.')> -1

  }




}
