import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router,
} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {TokenStore} from "../modules/auth/models/TokenStore";
import {Observable} from "rxjs";
import {DateTime} from "luxon";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve) => {

      const token = this.authService.retrieveTokenFromStorage();

      if (token && await this.validateToken(token, route)) {
          resolve(true);
      } else {
        this.router.navigate(['/auth/login'], {
          queryParams: {},
        }).then((res) => {
          resolve(false);
        });
      }

    });
  }

  validateToken(data: TokenStore, route: ActivatedRouteSnapshot): Promise<boolean> {

    return new Promise((resolve, reject) => {
      if (this.checkExpiry(data.expires_at)) {
        this.authService.currentTokenSubject.next(data);
        resolve(true);
      } else {
        this.authService.renewToken(data).then((RefreshTokenResult) => {
          this.authService.saveTokenToStorage(RefreshTokenResult);
          this.authService.currentTokenSubject.next(RefreshTokenResult);
          resolve(true);
        }).catch((RefreshTokenError) => {
          this.authService.currentTokenSubject.next(null);
          resolve(false);
        })
      }
    });
  }

  private checkExpiry(date: any): boolean {

    const now = DateTime.now();
    const expires = DateTime.fromISO(date);
    return now < expires;

  }

}
